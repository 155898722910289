
import { ScrapeTask } from "@/types";
import { format } from "date-fns/esm";
import { Component, Vue } from "vue-property-decorator";

import * as ScraperServices from '@/services/ScraperService';

@Component
export default class ScraperResults extends Vue {
  headers = [
    { text: "Datum", value: "createdAt" },
    { text: 'Voltooid', value: 'complete' },
    { text: "Organisatie", value: "PartnerId" },
    { text: "# Artikels", value: "articleCount" },
    { text: "# Nieuwe", value: "newArticleCount" },
  ];

  items = [] as ScrapeTask[];

  mounted(): void {
    this.fetchScrapeActions();
  }

  async fetchScrapeActions(): Promise<void> {
    this.items = await ScraperServices.GetScrapeTasks();
  }

  formatDate(d: string): string {
    return format(new Date(d), "dd-MM-yyyy hh:mm:ss");
  }
}
